
import React from 'react';
import classNames from 'classnames';

import { DateTime } from "luxon";

import useStore, { storeApi } from './store';
import shallow from 'zustand/shallow';
import produce from 'immer';

import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { Button, ButtonGroup } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';

function generateVersionLink(task) {
  if (!task.version) return (<span><i>latest</i></span>);

  const url = `https://github.com/Aclima/datapipes/releases/tag/${task.version}`;
  return (
    <a href={url} target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}}>{task.version}</a>
  );
}

export function externalLink(url, text) {
  return (
    <>
      {' '}
      <a href={url} target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}}>{text}</a>
      {' '}
    </>
  );
}

function stackdriverLink(task) {
  const console = "https://console.cloud.google.com/logs/query";
  const querySep = "%22%0A";
  const query = [
    `query=resource.type%3D%22k8s_container`,
    `resource.labels.cluster_name%3D%22default-private-k8s`,
    `resource.labels.namespace_name%3D%22default`,
    `timestamp%3E%3D%22${task.waiting_at}`,
    `resource.labels.pod_name%3D%22${task.pod_name}`,
  ];
  const tail = "%22;summaryFields=:true:32:beginning";
  const params = [
    "organizationId=316729536995",
    `project=${task.project}`
  ];

  return `${console};${query.join(querySep)}${tail}?${params.join('&')}`;
}

function gkeMetricsLink(task) {
  const console = `https://console.cloud.google.com/kubernetes/pod`;
  const zone = `us-central1-a`;
  const cluster= `default-private-k8s`;
  const namespace = `default`;

  return `${console}/${zone}/${cluster}/${namespace}/${task.pod_name}/details?project=${task.project}`;
}

// sort tasks via operator name
const sortFunction = (task1, task2) => {
  const first = `${task1.operator}`.toUpperCase();
  const second = `${task2.operator}`.toUpperCase();
  let sortedValue = 0; // strings are the same
  if (first > second) {
    sortedValue = 1
  } else if (first < second) {
    sortedValue = -1
  }
  return sortedValue;
}

function TaskItem(objOrStr) {
  const uuid = objOrStr.task_uuid ? objOrStr.task_uuid : objOrStr;
  const task = uuid === objOrStr ? null : objOrStr;

  const goToTask = () => {
    storeApi.setState({
      searchParams: produce(storeApi.getState().searchParams, (draft) => {
        draft.selected = uuid;
        draft.parent_uuid = null;
      }),
    });
    return false;
  };

  const link = (linkText) => (
    <button
      className="btn btn-link py-0"
      href={`/tasks?selected=${uuid}`}
      onClick={goToTask}
      style={{textDecoration: 'none'}}
    >
      {linkText}
    </button>
  );

  if (!task) {
    return (
      <li key={uuid}> {link(uuid)} (Loading...)</li>
    );
  }

  return (
    <li key={task.task_uuid}> {link(task.operator)} -- ( {task.status} )</li>
  );
}

const TaskStatusBadge = (task_status) => {
  let statusClass;
  switch(task_status.status) {
    case 'blocked':
    case 'waiting':
      statusClass = 'warning';
      break;
    case 'completed':
      statusClass = 'success';
      break;
    case 'scheduled':
    case 'running':
      statusClass = 'info';
      break;
    case 'failed':
    case 'cancelled':
      statusClass = 'danger';
      break;
    default:
      statusClass = 'primary';
  }
  return (<Badge className='mx-1' pill bg={statusClass}> {task_status.status}</Badge>);
}

const ShowChildrenButton = () => {
  const [selectedDetailsChildTask, searchChildrenTasks] = useStore(
    state => [
      state.selectedDetailsChildTask,
      state.searchChildrenTasks
    ],
    // shallow equality to do multi-pick (new array each time)
    shallow,
  );
  if (!selectedDetailsChildTask){
    return (<Button variant="secondary" disabled>
    No Matching Children
  </Button>)
  }
  return (<Button className='mx-1' variant="primary" onClick={() => searchChildrenTasks()}>
    Show Children
  </Button>)
}

const HideDetailsButton = () => {
  return (
    <Button className='mx-1' variant='secondary' onClick={
      () => storeApi.setState(
        {
          searchParams: produce(storeApi.getState().searchParams, (draft) => { draft.selected = null; })
        }
      )
    }
    >
      Hide Details
    </Button>)
}


export default function TaskDetails() {
  const [uuid, loading, error, details, cancelTask, rescheduleTask, parent_uuid] = useStore(
    state => [
      state.searchParams.selected,
      state.selectedDetailsLoading,
      state.selectedDetailsError,
      state.selectedDetails,
      state.cancelTask,
      state.rescheduleTask,
      state.searchParams.parent_uuid,
    ],
    // shallow equality to do multi-pick (new array each time)
    shallow,
  );

  if (!uuid) {
    return null;
  }

  const task = details[uuid];
  if (loading || !task) {
    return (
      <div className="card">
        <div className="card-header">
          Task {uuid}
        </div>
        <div className="card-body">
          <span className="spinner-border" role="status"></span>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div>{error.name}: [code {error.code}] {error.message}</div>
    );
  }

  // grab lists of dependents and dependencies
  const dependencies = [];
  if (task.dependencies) {
    task.dependencies.forEach(dep_uuid => {
      dependencies.push(details[dep_uuid] ? details[dep_uuid] : dep_uuid);
    });
  }
  const dependents = Object.values(details).filter(
    t => t.dependencies && t.dependencies.includes(uuid)
  );

  // make classes for styled elements
  const cardClass = classNames('card', 'mb-1', {
    'border-warning': ["blocked", "waiting"].includes(task.status),
    'border-success': task.status === 'completed',
    'border-info': ["scheduled", "running"].includes(task.status),
    'border-danger': task.status === 'failed',
  });

  const start = DateTime.fromISO(task.start_time, { zone: 'UTC' });
  const end = DateTime.fromISO(task.end_time, { zone: 'UTC' });

  const timings = (
    <div>
      <span><b>Interval:</b> </span>
      <span>{start.toISODate()}</span>
      <span>{start.toLocaleString(DateTime.TIME_24_WITH_SHORT_OFFSET)}</span>
      <span> until </span>
      <span>{end.toISODate()}</span>
      <span>{end.toLocaleString(DateTime.TIME_24_WITH_SHORT_OFFSET)}</span>
    </div>
  );

  let scheduled = null;
  if (task.scheduled_at) {
    const scheduled_at = DateTime.fromISO(task.scheduled_at);
    const by = task.scheduled_by ? (<span>by {task.scheduled_by}</span>) : null;
    scheduled = (
      <div>
        <span><b>Scheduled:</b> </span>
        {scheduled_at.toLocaleString(DateTime.DATETIME_MED)} {by}
      </div>
    );
  }

  let running = null;
  if (task.running_at) {
    const running_at = DateTime.fromISO(task.running_at);

    // links to monitoring tools
    let pod, sdlog, gkemetric = [null, null, null, null]
    if (task.pod_name) {
      pod = (<span> on {task.pod_name}</span>)
      gkemetric = externalLink(gkeMetricsLink(task), "(GKE Metrics)");
      sdlog = externalLink(stackdriverLink(task), "(SD Log)");
    }

    running = (
      <div>
        <span><b>Running:</b> </span>
        <span>{running_at.toLocaleString(DateTime.DATETIME_MED)}</span>
        {pod}
        {gkemetric}
        {sdlog}
      </div>
    );
  }

  const versionLink = generateVersionLink(task);
  const version = (
    <div>
      <span><b>Version:</b> </span>
      {versionLink}
    </div>
  );

  // render the task card
  return (
    <div className={cardClass}>
      <div className="card-header p-2">
        <span><b>Task</b> </span>
        <span>{uuid} -- </span>
        <span><b>{task.operator}</b></span>
        <TaskStatusBadge status={task.status}/>
      </div>

      <div className="card-body p-2">
        {timings}
        {scheduled}
        {running}
        {version}
        <div className="row">
          <div className="col">
            <span><b>Depends on:</b></span>
            <ul>
              {dependencies.sort(sortFunction).map(TaskItem)}
            </ul>
          </div>

          <div className="col">
            <span><b>Depending on this task:</b></span>
            <ul>
              {dependents.sort(sortFunction).map(TaskItem)}
            </ul>
          </div>
        </div>
      </div>

      <div className="card-footer p-2 text-right">
        <ButtonToolbar className="float-end">
          <ButtonGroup>
            <DropdownButton
              id="cancel"
              className='mx-1'
              title="Cancel"
              variant="danger"
              disabled={["blocked", "completed", "failed", "cancelled"].includes(task.status)}
            >
              <Dropdown.Item as="button"
                onClick={() => cancelTask(uuid)}>Cancel</Dropdown.Item>
              <Dropdown.Item as="button"
                onClick={() => cancelTask(uuid, null)}>Cancel and Clear Version</Dropdown.Item>
            </DropdownButton>

            <DropdownButton
              id="reschedule"
              title="Reschedule"
              className='mx-1'
              variant="danger"
              disabled={!["cancelled", "failed"].includes(task.status) && task.cleanup_at === null}
            >
              <Dropdown.Item as="button"
                onClick={() => rescheduleTask(uuid, parent_uuid !== '' ? parent_uuid : null)}>Same Version</Dropdown.Item>
              <Dropdown.Item as="button"
                onClick={() => rescheduleTask(uuid, parent_uuid !== '' ? parent_uuid : null, null)}>Latest Version</Dropdown.Item>
            </DropdownButton>
          </ButtonGroup>
          <ButtonGroup>
            <HideDetailsButton />
          </ButtonGroup>
          <ButtonGroup>
            <ShowChildrenButton />
          </ButtonGroup>
        </ButtonToolbar>
      </div>
    </div>
  );
}
