import React, { useEffect } from 'react';
import useStore from './store';


function SlotReports() {
    const [slotsReport, getSlotsReport, environmentToReservation] = useStore(state => [state.slotReport, state.getSlotsReport, state.environmentToReservation])
    useEffect(() => {
        getSlotsReport()
    }, [getSlotsReport]);
    if (slotsReport) {
        return (
            <>
                <h2>
                    What are Reservations?
                </h2>
                <p>
                    A reservation in BigQuery (BQ) is a bucket to hold slots, the atomic unit of compute in BQ. One may have a bucket per environment or can get more into dividing by environment, department, and so on and so forth.
                    We have our reservations listed below that are used in Datapipes.
                </p>
                <table style={{'tableLayout': 'fixed', width: '100%','borderCollapse': 'collapse', border: '3px', textAlign: 'left'}}>
                    <thead title='Datapipes BQ Reservations'>
                        <tr>
                            <th style={{width: '30%', padding: '20px'}} scope="col">Environment</th>
                            <th style={{width: '30%', padding: '20px'}} scope="col">Reservation</th>
                            <th style={{width: '20%', padding: '20px'}} scope="col">Slots Used</th>
                            <th style={{width: '15%', padding: '20px'}} scope="col">Slots Committed</th>
                            <th style={{width: '35%', padding: '20px'}} scope="col">Reported as of </th>
                        </tr>
                    </thead>
                    <tbody style={{textAlign: 'left'}}>
                        {Object.keys(slotsReport).map((key, index) => {
                            return (
                                <SlotReportPerReservation key={index} reservation={key} slotReport={slotsReport[key]} environmentLookup={environmentToReservation} />
                            )
                        })}
                    </tbody>
                </table>
            </>
        )
    }
    return (<EmptySlots />)

}

function SlotReportPerReservation(props) {
    const asOfDateTime = props.slotReport["slotReportAsOf"].toDate().toLocaleString();
    return (
        <>
            <tr>
                <td style={{padding:'20px', letterSpacing: '2px', textAlign: 'left'}}>{props.environmentLookup[props.reservation]}</td>
                <td style={{padding:'20px', letterSpacing: '2px', textAlign: 'left'}}>{props.reservation}</td>
                <td style={{padding:'20px', letterSpacing: '2px', textAlign: 'left'}}>{props.slotReport["slotsInUse"]}</td>
                <td style={{padding:'20px', letterSpacing: '2px', textAlign: 'left'}}>{props.slotReport["totalSlots"]}</td>
                <td style={{padding:'20px', letterSpacing: '2px', textAlign: 'left'}}>{asOfDateTime}</td>
            </tr>
        </>
    )
}


function EmptySlots() {
    return (<>
        <div>
            Unable to load slots 
        </div>
    </>)
}

export default SlotReports;