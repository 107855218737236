
import create from 'zustand';
import shallow from 'zustand/shallow';
import produce from 'immer';
import { DateTime } from "luxon";

import firebase, { auth } from './firebaseApp';

// default values for our url search params
const yesterday = DateTime.utc().minus({ days: 1 }).startOf('day');
const tomorrow = yesterday.plus({ days: 2 });
const weekAgo = yesterday.minus({ days: 7 });

export const paramDefaults = {
  'project': ({ path }) => path === '/tasks' ? 'aclima-lab' : 'aclima-test',
  'selected': '',
  'operator': 'workflows.done.daily',
  'status': ["scheduled", "blocked", "waiting", "running", "failed", "completed"].join(','),
  'field': 'start_time',
  'cond': 'since',
  'date1': weekAgo.toISODate(),
  'date2': tomorrow.toISODate(),
  'limit': '2000',
  'subtasks': 'false',
  'parent_uuid': '',

  'workflowName': 'daily',
  'downstreamOf': '',
  'upTo': '',

  'start': yesterday.toISODate(),
  'end': yesterday.toISODate(),

  'cpu': '',
  'memory': '',
  'storage': '',
  'extraOptions': '',

  'term': '',
  'filter': '',
}

// which params are relevant for which url?
const relevantParams = {
  '/tasks': [
    'project', 'selected', 'operator', 'status', 'field', 'cond', 'date1', 'date2', 'limit', 'subtasks', 'parent_uuid'
  ],
  '/workflow': ['project', 'workflowName', 'downstreamOf', 'upTo', 'start', 'end'],
  '/create': [
    'project', 'start', 'end', 'operator', 'version', 'cpu', 'memory', 'storage', 'extraOptions'
  ],
  '/docs': [
    'term', 'filter',
  ],
}

// function to return the values of url params as a dictionary
// if a param we expect is not set, it's initialized to the default
function parseParams(search, path) {
  const inUrl = new URLSearchParams(search);
  const params = {};
  Object.keys(paramDefaults).forEach((param) => {
    params[param] = inUrl.get(param);
    if (!params[param]) {
      const dp = paramDefaults[param];
      params[param] = typeof dp === 'function' ? dp({ path }) : dp;
    }
  });

  return params;
}

// datetime formats with a `Z` at the end, while pendulum uses `+00:00`
// so we force a specific format string that's like ISO8601 without zone
const qf = (dt) => dt.setZone('UTC').toFormat("yyyy-MM-dd'T'TT");

const [useStore, storeApi] = create((set, get, api) => ({
  tasksLoading: true,
  tasksLoadingError: null,

  // look into auth info
  auth: auth(),

  // enable cancelling of the job subscription
  cancelJobSub: () => { },

  // subscribe to job updates
  jobs: firebase.firestore().collection('job_tracker'),

  // slot reports in firebase
  slots: firebase.firestore().collection('slot_reporting'),
  slotReport: {},
  environmentToReservation: {
    'lab-reservation': 'aclima-lab',
    'test-reservation': 'aclima-test'
  },
  getSlotsReport: () => {
    const slotReports = get().slots;
    // iterate over each document reference
    slotReports.onSnapshot(slotReportSnapshot => {
      const docs = slotReportSnapshot.docs
      // load each document into slotReport object
      docs.forEach((slotReport) => {
        const slotReportData = slotReport.data();
        let slotReportPercent = 0;
        if (slotReportData["total_slots_available"] !== "0") {
          slotReportPercent = 100 * (parseInt(slotReportData["slots_in_use"]) / parseInt(slotReportData["total_slots_available"]))
        }
        const displaySlotReport = {
          "slotPercent": slotReportPercent,
          "slotsInUse": slotReportData["slots_in_use"],
          "totalSlots": slotReportData["total_slots_available"],
          "slotReportAsOf": slotReportData["slots_in_use_as_of"]
        };
        // use immer to add nested objects to slotReport object
        set({
          slotReport: produce(get().slotReport, (draft) => {
            draft[`${slotReport.id}`] = displaySlotReport;
          })
        });
      })
    });
  },
  queryBuild: () => {
    // return null if we're at the wrong path
    if (get().path !== '/tasks')
      return null;

    // begin building the query from the params in the URL
    const params = get().searchParams;
    let query = get().jobs;


    // project
    query = query.where('project', '==', params.project);

    // add status filter
    query = query.where('status', 'in', params.status.split(','));

    // operator
    if (params.operator) {
      query = query.where('operator', '==', params.operator);
    }

    if (params.parent_uuid) {
      query = query.where('parent_uuid', "==", params.parent_uuid);
    }

    // dont show subtasks unless we ask for them
    else if (params.subtasks === 'false') {
      query = query.where('is_subtask', '==', false);
    }

    // time filter
    if (params.field && params.cond && params.date1) {
      const zone = params.field === 'start_time' ? { zone: 'UTC' } : {};
      const start = DateTime.fromISO(params.date1, zone);

      if (params.cond === 'on' || params.cond === 'between') {
        const end = (params.cond === 'between' && params.date2) ?
          DateTime.fromISO(params.date2, zone) :
          start.endOf('day');

        query = query.where(params.field, '>=', qf(start));
        query = query.where(params.field, '<=', qf(end));
      } else {
        const op = {
          before: '<=',
          since: '>=',
        }[params.cond];
        query = query.where(params.field, op, qf(start));
      }

      // also order by that field
      query = query.orderBy(params.field, params.cond === 'on' ? 'asc' : 'desc');
    }

    // limit result set
    query = query.limit(params.limit ? Number(params.limit) : 2000);

    return query;
  },

  updateJobSub: () => {
    // always cancel existing subscription
    get().cancelJobSub();

    // check if we are currently able to make a jobs subscription
    const query = get().queryBuild();
    const taskGridApi = get().taskGridApi;
    if (!query || !taskGridApi) {
      return;
    }

    // mark ourselves as loading
    console.log(`loading matching tasks`);
    set({ tasksLoading: true });

    // run the query
    // https://firebase.google.com/docs/reference/js/firebase.firestore.Query.html#on-snapshot
    const canceller = query.onSnapshot(
      // gets new snapshot:
      // https://firebase.google.com/docs/reference/js/firebase.firestore.QuerySnapshot.html
      (qSnap) => {
        // agGrid transaction:
        // https://www.ag-grid.com/javascript-grid-data-update/#transactions
        const transaction = {
          add: [],
          update: [],
          remove: [],
        }

        // build a transaction from document changes:
        // https://firebase.google.com/docs/reference/js/firebase.firestore.DocumentChange.html
        qSnap.docChanges().forEach((dChange) => {
          const doc = dChange.doc;
          if (dChange.type === "removed" && doc.id) {
            transaction.remove.push({ task_uuid: doc.id });
          } else {
            // parse the task in the document
            const task = get().parseTaskData(doc.id, doc.data());

            // push task into an appropriate portion of the transaction
            const dest = dChange.type === "added" ? transaction.add : transaction.update;
            dest.push(task);
          }
        });

        // if we're loading, replace existing grid data
        if (get().tasksLoading) {
          get().taskGridApi.setRowData(transaction.add);
          set({ tasksLoading: false });
          // otherwise, commit the transaction
        } else {
          get().taskGridApi.batchUpdateRowData(transaction);
        }

        // the first time we load data, size columns to fit the data
        if (!get().everAutoSized) {
          get().resizeColumns()
          set({ everAutoSized: true });
        }
      },

      // gets errors:
      // https://firebase.google.com/docs/reference/js/firebase.firestore.FirestoreError
      (error) => {
        set({ tasksLoadingError: error });
      },
    );

    // save it so we can cancel it later
    set({ cancelJobSub: canceller });
  },

  // subscribe to task details
  selectedDetails: {},
  selectedDetailsChildTask: null,
  selectedDetailsLoading: false,
  selectedDetailsError: false,
  cancelSelectedSub: () => { },
  updateSelectedSub: () => {
    // always cancel existing subscription
    get().cancelSelectedSub();
    set({ selectedDetailsChildTask: null });

    // check if we are currently able to make a jobs subscription
    const uuid = get().searchParams.selected;
    if (!uuid) {
      set({ selectedDetails: {}, selectedDetailsLoading: false, });
      return;
    }

    // mark ourselves as loading
    console.log(`loading selected task ${uuid}`);
    set({ selectedDetailsLoading: true });

    // https://firebase.google.com/docs/reference/js/firebase.firestore.Query.html#on-snapshot
    const col = get().jobs;
    col.where("parent_uuid", "==", uuid).limit(1).onSnapshot((qSnap) => {
      if (qSnap.empty) {
        return;
      }
      const childDoc = qSnap.docs.map(doc => doc.data())[0];
      set({ selectedDetailsChildTask: childDoc }, false, "setting optional child task");
    });
    const cancelDetails = col.where('task_uuid', '==', uuid).onSnapshot(
      (qSnap) => {
        qSnap.forEach((doc) => get().updateSelectedDetails(doc));

        // add a query to look at dependencies of the task. we can't do this until
        // we've loaded the task itself
        if (get().selectedDetailsLoading) {
          const selectedTask = get().selectedDetails[uuid];

          // if the task is loaded, we can query for it's dependencies
          if (selectedTask) {
            // if it has no dependencies, we have nothing to do
            if (selectedTask.dependencies && selectedTask.dependencies.length > 0) {
              for (let i = 0; i < selectedTask.dependencies.length; i += 10) {
                const endSlice = Math.min(i + 10, selectedTask.dependencies.length);
                const depsSubArray = selectedTask.dependencies.slice(i, endSlice);
                const existingCancel = get().cancelSelectedSub;
                const cancelDeps = col.where('task_uuid', 'in', depsSubArray).onSnapshot(
                  qSnap => qSnap.forEach((doc) => get().updateSelectedDetails(doc)),
                );

                // new cancel function cancels existing subs, plus the new sub we just made
                const newCancel = () => {
                  cancelDeps();
                  existingCancel()
                }
                set({ cancelSelectedSub: newCancel });
              }
            }

            // clear the loading flag
            set({ selectedDetailsLoading: false });
          }
        }
      },

      // https://firebase.google.com/docs/reference/js/firebase.firestore.FirestoreError
      (error) => {
        set({ selectedDetailsError: error });
      },
    );

    // query for tasks that depend on this task
    const cancelDependents = col.where('dependencies', 'array-contains', uuid).onSnapshot(
      qSnap => qSnap.forEach((doc) => get().updateSelectedDetails(doc)),
    );

    // save the cancellers; we have two now, but will have another once the task loads
    set({
      cancelSelectedSub: () => { cancelDetails(); cancelDependents(); },
    });
  },
  updateSelectedDetails: (doc) => {
    set({
      selectedDetails: produce(
        get().selectedDetails,
        draft => {
          draft[doc.id] = doc.data();
        }
      )
    });
  },

  createTasks: (tasks, onSuccess, onError) => {
    const batch = firebase.firestore().batch();

    tasks.forEach(task => {
      const taskRef = firebase.firestore().collection('job_tracker').doc(task.task_uuid);
      batch.set(taskRef, task);
    })

    batch.commit().then(onSuccess).catch(onError)
  },

  rescheduleTask: (uuid, parent_uuid, version) => {
    const col = get().jobs;
    const taskRef = col.doc(uuid);

    const email = get().auth.currentUser.email;
    const name = email.substring(0, email.indexOf("@"));

    const updates = {
      status: "scheduled",
      scheduled_by: name,
      dispatched_by: null,
      cleanup_by: null,
      attempts: [],

      scheduled_at: DateTime.utc().toISO(),
      waiting_at: null,
      running_at: null,
      completed_at: null,
      cleanup_at: null,
      parent_uuid: parent_uuid,

      pod_name: null,
    };

    if (typeof version !== 'undefined')
      updates['version'] = version;

    taskRef.update(updates);
  },

  cancelTask: (uuid, version) => {
    const col = get().jobs;
    const taskRef = col.doc(uuid);

    const updates = {
      status: "cancelled",
      completed_at: DateTime.utc().toISO(),
    };

    if (typeof version !== 'undefined')
      updates['version'] = version;

    taskRef.update(updates);
  },

  searchChildrenTasks: () => {
    set({
      searchParams: produce(get().searchParams, (draft) => {
        draft['operator'] = get().selectedDetailsChildTask.operator;
        draft['parent_uuid'] = get().selectedDetailsChildTask.parent_uuid;
      })
    })
  },

  updateOperator: (operator) => {
    set({
      searchParams: produce(get().searchParams, (draft) => {
        draft['operator'] = operator;
        // Clear the parent_uuid.
        draft['parent_uuid'] = '';
      })
    })
  },

  // handle interactions with the task grid
  taskGridApi: null,
  taskGridColApi: null,
  taskGridCols: null,

  onGridReady: ({ api: gridApi, columnApi }) => {
    console.log("grid is ready");

    // save api interfaces to the grid
    set({
      taskGridApi: gridApi,
      taskGridColApi: columnApi,
      taskGridCols: columnApi.getAllColumns(),
    });

    // attach a selection listener
    gridApi.addEventListener('selectionChanged', get().updateGridSelection);

    // sort by created_at
    gridApi.setSortModel([{ colId: 'start_time', sort: 'desc' }, { colId: 'created_at', sort: 'desc' }]);
  },
  everAutoSized: false,
  resizeColumns: () => {
    const allColumnIds = get().taskGridCols.map((col) => col.colId);
    const unsized = allColumnIds.filter((id) => id !== 'context');
    get().taskGridColApi.autoSizeColumns(unsized);
  },
  updateGridSelection: () => {
    const rows = get().taskGridApi.getSelectedRows();
    if (rows.length > 0) {
      set({
        searchParams: produce(get().searchParams, (draft) => {
          draft.selected = rows[0].task_uuid;
        }),
      });
    }
  },

  // if we unmount the task grid
  onGridUnmount: () => {
    const taskGridApi = get().taskGridApi;
    if (taskGridApi) { taskGridApi.setRowData([]) };
    set({
      taskGridApi: null,
      taskGridColApi: null,
      taskGridCols: null,
    });
  },

  // utility method to create cannonical task representation
  parseTaskData: (id, task) => {
    // make sure all tasks have an id
    task.task_uuid = id;

    // turn date column fields into date objects
    get().taskGridCols.forEach((col) => {
      if (col.colDef.filter === "agDateColumnFilter") {
        const field = col.colDef.field;
        if (task[field]) {
          task[field] = new Date(task[field]);
          task[field].isUTC = col.colDef.isUTC;
        } else {
          task[field] = null;
        }
      }
    });
    return task;
  },

  // keep url bar updaed
  history: null,
  path: null,
  search: null,
  searchParams: {},
  paramsFromSearch: () => {
    const path = get().path;
    const inUrl = parseParams(get().history.location.search, path);
    const inStore = get().searchParams;
    const relevant = relevantParams[path] || [];

    set({
      searchParams: produce(inStore, (draft) => {
        Object.keys(inUrl).forEach((param) => {
          if (relevant.includes(param) && inStore[param] !== inUrl[param]) {
            draft[param] = inUrl[param];
          }
        });
      })
    });
  },
  genSearchFromParams: (path) => {
    const inStore = get().searchParams;
    const inUrl = new URLSearchParams();
    const relevant = relevantParams[path] || [];

    Object.entries(inStore).forEach(([param, value]) => {
      if (value && relevant.includes(param)) {
        inUrl.set(param, value);
      }
    });

    return inUrl;
  },
  setSearchFromParams: () => {
    const path = get().path;
    const inUrl = get().genSearchFromParams(path);

    // push history
    get().history.push(path + '?' + inUrl.toString());
  },
  
}));

// reload firebase query when relevant parameters change
storeApi.subscribe(
  storeApi.getState().updateJobSub,
  state => [
    state.searchParams.operator,
    state.searchParams.status,
    state.searchParams.project,
    state.searchParams.field,
    state.searchParams.cond,
    state.searchParams.date1,
    state.searchParams.date2,
    state.searchParams.limit,
    state.searchParams.subtasks,
    state.searchParams.parent_uuid,
    state.taskGridApi,
  ],
  // use shallow equality because, above, we create a new array each time
  shallow
);


// reload selected subscription when the selected row changes
storeApi.subscribe(
  storeApi.getState().updateSelectedSub,
  state => state.searchParams.selected,
);

// update state if the search string changes
storeApi.subscribe(
  storeApi.getState().paramsFromSearch,
  state => [state.search, state.path],
  shallow
);

// update the location if some of the local state changes
storeApi.subscribe(
  storeApi.getState().setSearchFromParams,
  state => state.searchParams,
);



// for debugging
window.storeApi = storeApi;

export { storeApi };
export default useStore;