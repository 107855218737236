import React, { useEffect } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  useLocation,
} from "react-router-dom";
import classNames from 'classnames';

// firebase auth
import withFirebaseAuth from 'react-with-firebase-auth'
import firebaseApp, { GoogleAuthProvider } from './firebaseApp';

// custom components
import Home from './Home';
import Tasks from './Tasks';
import Create from './Create';
import Workflow from './Workflow';
import Docs from './Docs';
import SlotReports from './Slots';

// store
import useStore, { storeApi } from './store';

function RightSide({ user, signOut, signIn }) {
  if (user) {
    return (
      <>
        <Navbar.Text className="ml-1">
          Welcome, {user.displayName}
        </Navbar.Text>
        <Link to="/" className="nav-link" onClick={signOut}>
          Sign Out
        </Link>
      </>
    );
  } else {
    return (
      <button className="btn btn-primary btn-sm" onClick={signIn}>Sign In</button>
    );
  }
}

function NavLinks({ user }) {
  // we subscribe to param updates so we can re-render the links with params
  useStore(state => state.searchParams);
  const pp = useStore(state => state.genSearchFromParams);
  const curPath = useStore(state => state.path);

  const pages = [
    {name: 'Home', path: '/', },
    {name: 'Tasks', path: '/tasks', },
    {name: 'Create Task', path: '/create', },
    {name: 'Run Workflow', path: '/workflow', },
    {name: 'Documentation', path: '/docs', },
    {name: 'BigQuery Usage Reports', path: '/slots'},
  ];

  const links = pages.map(({name, path }) => {
    const liC = classNames('nav-item ');
    const linkC = classNames('nav-link', {active: path === curPath});
    const url = `${path}?${pp(path)}`;

    return (
      <li className={ liC } key={ name }>
        <Link className={ linkC } to={ url }>{name}</Link>
      </li>
    );
  });

  if (user) {
    return (
      <ul className="nav nav-tabs">
        { links }
      </ul>
    );
  } else {
    return null;
  }
}

function SaveLocation() {
  const location = useLocation();
  const history = useHistory();

  // keep a reference to history/location in the store
  useEffect(
    () => storeApi.setState({ history, path: location.pathname, search: location.search }),
    [history, location]
  );

  return null;
}

function LoggedInRoutes() {
  return (
    <Switch>
      <Route exact path="/tasks" component={ Tasks } />
      <Route exact path="/create">
        <Create />
      </Route>
      <Route exact path="/workflow">
        <Workflow />
      </Route>
      <Route exact path="/docs">
        <Docs />
      </Route>
      <Route exact path="/slots">
          <SlotReports />
      </Route>
      <Route>
        <Home />
      </Route>
    </Switch>
  );
}

function LogInCard({ user, signIn }) {
  return (
    <div className="row align-items-center justify-content-center mt-3">
      <div className="card">
        <div className="card-header">
          Welcome to Datapipes Tracker
        </div>
        <div className="card-body">
          <h5 className="card-title">You are not signed in</h5>
          <button className="btn btn-primary" onClick={ signIn }>Sign In with Google</button>
        </div>
      </div>
    </div>
  );
}

function Body({ user, signIn }) {
  if (user) {
    return ( <LoggedInRoutes /> );
  } else {
    return (<LogInCard user={ user } signIn={ signIn} />);
  }
}

function App({ user, signOut, signInWithGoogle }) {
  return (
    <Router>
      <Route component={ SaveLocation } />

      <Navbar bg="light" expand="md" style={{"paddingRight": "1rem", "paddingLeft": "1rem"}}>
        <Link className="navbar-brand" to="/">
            <img
              src="/pipe.png"
              width="30"
              height="30"
              className="d-inline-block align-top"
              style={{marginRight: "0.5rem"}}
              alt="Datapipes Logo"
            />
            Datapipes
        </Link>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <NavLinks user={ user } />
        <Navbar.Collapse className="justify-content-end">
          <RightSide user={ user } signOut={ signOut } signIn={ signInWithGoogle } />
        </Navbar.Collapse>
      </Navbar>

      <div className="container-fluid pl-5 pr-5" style={{"paddingRight": "3rem", "paddingLeft": "3rem"}}>
        <Body user={ user } signIn={ signInWithGoogle } />
      </div>
    </Router>
  );
}

// configure auth
const firebaseAppAuth = firebaseApp.auth();
const providers = {
  googleProvider: new GoogleAuthProvider(),
};
providers.googleProvider.setCustomParameters({"hd": "aclimalabs.com"});

// wrap app in firebase auth component
export default withFirebaseAuth({
  providers,
  firebaseAppAuth,
})(App);
