
import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';

import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Form from 'react-bootstrap/Form'

import useDebounce from './useDebounce';
import useStore, { storeApi } from './store';
import produce from 'immer';
import shallow from 'zustand/shallow';
import { useDocumentData } from 'react-firebase-hooks/firestore';

import firebase from './firebaseApp';

// what are our possible statuses?
// see `JobStatus` in https://github.com/Aclima/datapipes/blob/master/utils/tracker.py
const statuses = [
  "scheduled",
  "blocked",
  "waiting",
  "running",
  "failed",
  "completed",
  "cancelled"
];

const defaultStatuses = [
  "scheduled",
  "blocked",
];

function OperatorDropdown() {
  const updateOperator = useStore(state => state.updateOperator);

  // grab operator names from firestore docs
  const docRef = firebase.firestore().doc(`docs/operator_names`);
  const [doc, loading, error] = useDocumentData(docRef);
  const operators = (loading || error || !doc) ? [] : Object.keys(doc);
  operators.sort();

  // propagate change
  const operatorRef = useRef(storeApi.getState().searchParams?.operator)
  useEffect(() => storeApi.subscribe(
    state => (operatorRef.current = state.searchParams.operator)
  ), []);

  const options = operators.sort().map((op) => (
    <option value={op} key={op}>{op}</option>
  ));

  return (
    <select
      value={operatorRef.current}
      onChange={(e) => updateOperator(e.target.value)}
      className="form-control form-control-sm"
      style={{ width: 200 }}
    >
      <option value='' default>All</option>
      {options}
    </select>
  );
}

function StatusDropdown({ value, onChange }) {
  // update the status on interactions with checkboxes
  const [status, setStatus] = useState(value.split(','));

  // update state from props if they change
  useMemo(() => setStatus(value.split(',')), [value]);

  // how to update the status?
  const updateStatus = useCallback((val) => {
    console.log(`val ${val} with ${status} of ${statuses} (${defaultStatuses})`);
    if (val === 'any') {
      if (status.length === statuses.length) {
        setStatus(defaultStatuses);
      } else {
        setStatus(statuses);
      }
    } else {
      if (status.includes(val)) {
        setStatus(status.filter(s => s !== val));
      } else {
        setStatus(status.concat([val]));
      }
    }
  }, [status, setStatus]);

  // factory method for generating event handlers
  const makeClicker = useCallback((val) => {
    return (evt) => updateStatus(val)
  }, [updateStatus]);

  // generate all checkbox items
  const checkboxItems = statuses.map((opt) => (
    <Dropdown.Item
      as="div"
      key={opt}
      onClick={makeClicker(opt)}
    >
      <Form.Check
        label={opt}
        readOnly
        checked={status.length === statuses.length || status.includes(opt)}
        onClick={makeClicker(opt)}
      />
    </Dropdown.Item>
  ));

  // dropdown title
  const title = status.length === statuses.length ? 'Any Status' : (
    status.length < 3 ? status.join(", ") : `${status.length} of ${statuses.length}`
  );

  // prevents dropdown from closing when clicking on things in it
  // see: https://github.com/react-bootstrap/react-bootstrap/issues/1490#issuecomment-495341254
  // we use the dropdown closing to emit the onChange event
  const [show, setShow] = useState(false);
  const handleToggle = useCallback((isOpen, event, metadata) => {
    if (isOpen || event?.source !== 'select') {
      setShow(isOpen);
      if (!isOpen) {
        onChange && onChange({ target: { value: status.join(',') } });
      }
    }
  }, [setShow, status, onChange]);

  return (
    <DropdownButton
      id="pick-status"
      size="sm"
      title={title}
      show={show}
      onToggle={handleToggle}
    >
      <Dropdown.Item as="div" onClick={makeClicker('any')}>
        <Form.Check label="Any Status" readOnly checked={status.length === statuses.length} />
      </Dropdown.Item>

      <Dropdown.Divider />

      {checkboxItems}

    </DropdownButton>
  );
}

function LimitInput({ value, onChange }) {
  const [limit, setLimit] = useState(value);
  useEffect(() => setLimit(value), [value, setLimit]);

  const debouncedLimit = useDebounce(limit, 500);
  useEffect(
    () => onChange({ target: { value: debouncedLimit } }),
    [onChange, debouncedLimit]
  );

  return (
    <input type="number" value={limit} onChange={(evt) => setLimit(evt.target.value)} />
  );
}

function TaskFilter() {
  const [operator, status, project, field, cond, date1, date2, subtasks, limit] = useStore(
    state => [
      state.searchParams.operator,
      state.searchParams.status,
      state.searchParams.project,
      state.searchParams.field,
      state.searchParams.cond,
      state.searchParams.date1,
      state.searchParams.date2,
      state.searchParams.subtasks,
      state.searchParams.limit
    ],
    shallow
  );

  if (!project || !status) return null;

  const updaterMaker = (param) => {
    return (evt) => {
      storeApi.setState({
        searchParams: produce(storeApi.getState().searchParams, (draft) => {
          draft[param] = evt.target.value;
        }),
      });
    }
  };

  const toggleMaker = (param) => {
    const updater = updaterMaker(param);
    return (evt) => {
      updater({ target: { value: String(evt.target.checked) } });
    }
  };

  return (
    <table className="table table-sm">
      <thead>
        <tr>
          <th>Operator</th>
          <th>Status</th>
          <th>Project</th>
          <th>Query Field</th>
          <th>Query Condition</th>
          <th>Date</th>
          {cond === 'between' ? <th>End Date</th> : null}
          <th>Limit</th>
          <th>Subtasks</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <OperatorDropdown value={operator} onChange={updaterMaker("operator")} />
          </td>

          <td>
            <StatusDropdown
              value={status} onChange={updaterMaker("status")} />
          </td>

          <td>
            <select
              value={project}
              onChange={updaterMaker("project")}
              className="form-control form-control-sm">
              <option value="aclima-lab">Lab</option>
              <option value="aclima-test">Test</option>
            </select>
          </td>

          <td>
            <select
              value={field}
              onChange={updaterMaker("field")}
              className="form-control form-control-sm">
              <option value="created_at">Created At</option>
              <option value="start_time">Start Time</option>
            </select>
          </td>

          <td>
            <Form.Check onChange={updaterMaker('cond')} checked={cond === "before"}
              inline value="before" name="cond" label="Before" type="radio" id="cond-before" />
            <Form.Check onChange={updaterMaker('cond')} checked={cond === "on"}
              inline value="on" name="cond" label="On" type="radio" id="cond-on" />
            <Form.Check onChange={updaterMaker('cond')} checked={cond === "since"}
              inline value="since" name="cond" label="Since" type="radio" id="cond-since" />
            <Form.Check onChange={updaterMaker('cond')} checked={cond === "between"}
              inline value="between" name="cond" label="Between" type="radio" id="cond-since" />
          </td>

          <td>
            <input type="date" value={date1} onChange={updaterMaker("date1")} />
          </td>

          {cond === "between" ? (
            <td>
              <input type="date" value={date2} onChange={updaterMaker("date2")} />
            </td>
          ) : null}

          <td>
            <LimitInput value={limit} onChange={updaterMaker("limit")} />
          </td>

          <td>
            <Form.Check onChange={toggleMaker('subtasks')} checked={subtasks === "true"}
              inline value="true" name="subtasks" label="Show?" type="switch" id="subtasks" />
          </td>

          <td>
            <button
              className="btn btn-secondary btn-sm"
              onClick={() => { storeApi.getState().resizeColumns() }}
            >
              Resize
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default TaskFilter;
