
import React, {useCallback, useState} from 'react';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Toast from 'react-bootstrap/Toast';

import { DateTime } from "luxon";
import { v4 as uuidv4 } from 'uuid';

import { useDocumentData } from 'react-firebase-hooks/firestore';
import useStore, { storeApi} from "./store";
import shallow from 'zustand/shallow';
import produce from 'immer';

import firebase from './firebaseApp';

export const projects = [
  'aclima-test',
  'aclima-lab',
]

export function CreatedToast({numTasks, error, show, handleClose}) {
  if (!show) {
    return null;
  }

  if (error) {
    return (
      <Toast onClose={handleClose}>
        <Toast.Header>
         <strong className="mr-auto alert-danger">Error Creating tasks</strong>
        </Toast.Header>
        <Toast.Body>{error.name}: [{error.code}] {error.message}</Toast.Body>
      </Toast>
    );
  }

  return (
    <Toast onClose={handleClose}>
      <Toast.Header>
        <strong>Tasks Created</strong>
      </Toast.Header>
      <Toast.Body>Created {numTasks} task(s)</Toast.Body>
    </Toast>
  );
}

export function createTask({project, operator, version, start, end, cpu, memory, storage, extraOptions}, onSuccess, onError) {
  const email = storeApi.getState().auth.currentUser.email;
  const name = email.substring(0, email.indexOf("@"));

  let startDate = DateTime.fromISO(start, { zone: 'UTC' }).startOf("day");
  const endDate = DateTime.fromISO(end, { zone: 'UTC' }).endOf("day");

  const dates = []
  while (startDate < endDate) {
    dates.push(startDate);
    startDate = startDate.plus({ days: 1 });
  }

  // build the context
  const context = {};
  if (cpu) context.cpu_request = cpu;
  if (memory) context.memory_request = memory;
  if (storage) context.storage_request = storage;
  if (extraOptions) context.additional_args = ['--extra-opts', extraOptions];

  // when were tasks created/scheduled
  const created = DateTime.utc().toISO();

  const tasks = dates.map((date) => {
    const task_uuid = uuidv4().replace(/-/g, "");
    console.log("Creating task with uuid " + task_uuid);

    return {
      task_uuid,
      operator: operator || null,
      status: "scheduled",
      start_time: date.startOf("day").toISO(),
      end_time: date.endOf("day").toISO(),
      context,

      scheduled_by: name,
      dispatched_by: null,
      cleanup_by: null,

      created_at: created,
      scheduled_at: created,
      waiting_at: null,
      running_at: null,
      completed_at: null,
      cleanup_at: null,

      version: version || null,
      pod_name: null,
      project,
      dependencies: [],
      parent_uuid: null,

      is_subtask: false,
      attempts: [],
    };
  });

  console.log(tasks);

  const onSuccessWithNumTasks = () => {
    onSuccess(tasks.length);
  }

  storeApi.getState().createTasks(tasks, onSuccessWithNumTasks, onError)
}

export default function Create() {
  const docRef = firebase.firestore().doc(`docs/operator_names`);
  const [doc, loading, error] = useDocumentData(docRef);
  const allOperators = (loading || error || !doc) ? []: Object.keys(doc);

  // not all operators are creatable here; filter some out
  const operators = allOperators.filter((op) => !op.startsWith('workflows.'));
  operators.sort();

  // for end filters
  const today = DateTime.utc().startOf('day');

  const formValues = useStore(
    state => ({
      project: state.searchParams.project,
      start: state.searchParams.start,
      end: state.searchParams.end,
      operator: state.searchParams.operator,
      version: state.searchParams.version,
      cpu: state.searchParams.cpu,
      memory: state.searchParams.memory,
      storage: state.searchParams.storage,
      extraOptions: state.searchParams.extraOptions,
    }),
    shallow
  );

  const [submitting, setSubmitting] = useState(false);
  const [numTasksCreated, setNumTasksCreated] = useState(0);
  const [taskCreateError, setTaskCreateError] = useState(null);

  const handleChange = (evt) => {
    const {name, value} = evt.target;
    storeApi.setState({
      searchParams: produce(storeApi.getState().searchParams, (draft) => {
        draft[name] = value;
      }),
    });
  };

  const onSuccess = useCallback((numTasks) => {
    setSubmitting(false);
    setNumTasksCreated(numTasks);
  }, [setSubmitting, setNumTasksCreated]);

  const onError = useCallback((error) => {
    console.log("Error adding task: " + error)
    setSubmitting(false);
    setTaskCreateError(error);
  }, [setSubmitting, setTaskCreateError]);

  const handleSubmit = useCallback((evt) => {
    setSubmitting(true);
    evt.preventDefault();
    createTask(formValues, onSuccess, onError);
  }, [formValues, setSubmitting, onSuccess, onError]);

  const showToast = useCallback(() => {
    return (taskCreateError || numTasksCreated > 0);
    },
    [taskCreateError, numTasksCreated]
  );

  const handleToastClose = useCallback(() => {
    setNumTasksCreated(0);
    setTaskCreateError(null);
  }, [setNumTasksCreated, setTaskCreateError]);

  return (
    <div>
      <Modal show={submitting}>
        <Modal.Header>
          <Modal.Title>Creating Task...</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span className="spinner-border" role="status"></span>
        </Modal.Body>
      </Modal>

      <Row className="justify-content-center">
        <CreatedToast
          numTasks={numTasksCreated}
          error={taskCreateError}
          show={showToast()}
          handleClose={handleToastClose}/>
      </Row>

      <Form onSubmit={handleSubmit}>
        <Form.Group as={Row}>
          <Form.Group controlId="formProject" as={Col} md="auto">
            <Form.Label>Project</Form.Label>
            <Form.Control as="select" name="project" value={formValues.project} onChange={handleChange}>
              {projects.map(p => <option value={p} key={p}>{p}</option>)}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="formOperator" className="ml-1" as={Col} md="auto">
            <Form.Label>Operator</Form.Label>
            <Form.Control as="select" name="operator" value={formValues.operator} onChange={handleChange}>
              <option value="" key="default"/>
              {operators.map(op => <option value={op} key={op}>{op}</option>)}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="formVersion" className="ml-1" as={Col} md="auto">
            <Form.Label>Version</Form.Label>
            <Form.Control
              type="text"
              name="version"
              value={formValues.version}
              onChange={handleChange}
              placeholder="e.g. v0.0.1"
            />
            <Form.Text className="text-muted">
              If omitted, latest release version will be used.
            </Form.Text>
          </Form.Group>
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Group controlId="formStart" as={Col} md="auto">
            <Form.Label>Start date</Form.Label>
            <Form.Control
              max={ formValues.end }
              type="date" name="start" value={formValues.start} onChange={handleChange}/>
          </Form.Group>
          <Form.Group controlId="formEnd" className="ml-1" as={Col} md="auto">
            <Form.Label>End date</Form.Label>
            <Form.Control
              min={formValues.start}
              max={today.toISODate()}
              type="date" name="end" value={formValues.end} onChange={handleChange}/>
          </Form.Group>
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Group controlId="formExtraOptions" as={Col} md={8}>
            <Form.Label>Extra Options</Form.Label>
            <Form.Control
              type="text"
              name="extraOptions"
              value={formValues.extraOptions}
              onChange={handleChange}
              placeholder="e.g. overwrite=True,auto_priority=True (no spaces)"
            />
          </Form.Group>
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Group controlId="formMemory" as={Col} md="auto">
            <Form.Label>Memory</Form.Label>
            <Form.Control
              type="text" name="memory" value={formValues.memory} onChange={handleChange}
              placeholder="e.g. 24Gi"
            />
          </Form.Group>
          <Form.Group controlId="formCpu" as={Col} md="auto">
            <Form.Label>CPU</Form.Label>
            <Form.Control
              type="text" name="cpu" value={formValues.cpu} onChange={handleChange}
              placeholder="e.g. 800m"
            />
          </Form.Group>
          <Form.Group controlId="formStorage" as={Col} md="auto">
            <Form.Label>Storage</Form.Label>
            <Form.Control
              type="text" name="storage" value={formValues.storage} onChange={handleChange}
              placeholder="e.g. 60G"
            />
          </Form.Group>
        </Form.Group>

        <Button type="submit" disabled={submitting}>Submit</Button>

      </Form>
    </div>
  );
}

window.uuidv4 = uuidv4
