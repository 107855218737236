
import React, { useCallback } from 'react';
import { Link } from "react-router-dom";
import { externalLink } from './TaskDetails';

import useStore from './store';

export default function Home() {
  // we subscribe to param updates so we can re-render the links with params
  useStore(state => state.searchParams);

  const pp = useStore(state => state.genSearchFromParams);
  const dest = useCallback(
    (url) => `${url}?${pp(url)}`,
    [pp]
  );

  return (
    <div>
      <h2>
      Welcome to DataPipes!
      </h2>

      <p>
        Please select one of the following pages to interact with DataPipes:
      </p>

      <dl className="row">
        <dt className="col-sm-2">
          <Link to={ dest('/tasks') }> View Tasks </Link>
        </dt>
        <dd className="col-sm-10">
          -- Check the status of previously created tasks; cancel/reschedule them
        </dd>

        <dt className="col-sm-2">
          <Link to={ dest('/create') }> Create a Task </Link>
        </dt>
        <dd className="col-sm-10">
          -- Create a single, independent task
        </dd>

        <dt className="col-sm-2">
          <Link to={ dest('/workflow') }> Run a Workflow </Link>
        </dt>
        <dd className="col-sm-10">
          -- Schedule an entire workflow for execution
        </dd>

        <dt className="col-sm-2">
          <Link to={ dest('/docs') }> View Documentation </Link>
        </dt>
        <dd className="col-sm-10">
          -- See what different tasks, operators, and tables are
        </dd>

      </dl>

      <p>
        You might find these additional resources helpful:
      </p>

      <ul>
        <li>
          The DataPipes User Guide
          { externalLink('https://docs.google.com/presentation/d/1MdPaO_4jhuMpoXkgx3ZWQJD3GYE889FLoZ_148o0F8Y/edit#slide=id.g829e0e8d1d_0_199', 'in Google Slides') }
          (thanks, Adam!).
        </li>

        <li>
          The BQ datasets that our tasks modify, in
          { externalLink('https://console.cloud.google.com/bigquery?project=aclima-lab&folder=&organizationId=&p=aclima-lab&d=datapipes&page=dataset', 'aclima-lab') }
          and
          { externalLink('https://console.cloud.google.com/bigquery?project=aclima-test&p=aclima-test&d=datapipes&page=dataset', 'aclima-test') }.
        </li>

        <li>
          The code for DataPipes operators/workflows
          { externalLink('https://github.com/Aclima/datapipes', 'in github') }.
        </li>

        <li>
          The code for this UI
          { externalLink('https://github.com/Aclima/datapipes-ui', 'in github') }.
        </li>

        <li>
          The
          { externalLink('https://console.firebase.google.com/u/0/project/datapipes/database/firestore/data~2Fjob_tracker', 'firestore console') }
          for the data in this UI.
        </li>
      </ul>
    </div>
  );
}

