
import React, { useState, useEffect } from 'react';

import Modal from 'react-bootstrap/Modal';

import useDebounce from './useDebounce';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import produce from 'immer';

import useStore, { storeApi } from './store';
import firebase from './firebaseApp';

function getTerm(terms, term_name, term_type) {
  return terms.find(
    (term) => (term.term_name === term_name && (term_type ? term.term_type === term_type : true))
  );
}

function TermFilter({ filter, onChange }) {
  const [localFilter, setLocalFilter] = useState(filter);
  useEffect(() => setLocalFilter(filter), [filter]);

  const debouncedFilter = useDebounce(localFilter, 100);
  useEffect(
    () => onChange({ target: { value: debouncedFilter } }),
    [onChange, debouncedFilter]
  );

  return (
    <div className="form-row">
      <div className="col">
        <input
          className="form-control"
          placeholder="Search for a document..."
          type="text"
          value={ localFilter }
          onChange={ (evt) => setLocalFilter(evt.target.value) } />
      </div>
    </div>
  );
}

const mono = {'fontFamily': 'monospace'};

function Concept({ term }) {
  return (
    <p>
      {term.description}
    </p>
  );
}

function Table({ term }) {
  return (
    <div>
      <h4>Written by</h4>
      <ul>
        { term.written_by.length > 0 ?
            term.written_by.map((f) => <li style={mono} key={f}>{f}</li>)
            : <li><i>None</i></li>
        }
      </ul>

      <h4>Read By</h4>
      <ul>
        { term.read_by.length > 0 ?
            term.read_by.map((f) => <li style={mono} key={f}>{f}</li>)
            : <li><i>None</i></li>
        }
      </ul>

      <h4>Clustering</h4>
      <table className="table table-sm">
        <tbody>
          <tr>
            { term.clustering ?
                term.clustering.map((f) => <td key={f} style={mono}>{f}</td>)
                : <td className="font-italic">Table is not clustered</td>
            }
          </tr>
        </tbody>
      </table>

      <h4>Schema</h4>
      <table className="table table-striped table-sm">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Type</th>
            <th scope="col">Mode</th>
            <th scope="col">Desc.</th>
          </tr>
        </thead>

        <tbody>
          { (term.schema || []).map((f) => (
            <tr key={f.name}>
              <th scope="row">{f.name}</th>
              <td>{f.type}</td>
              <td>{f.mode}</td>
              <td>{f.description}</td>
            </tr>
          )) }
        </tbody>
      </table>
    </div>
  );

}

function Operator({ term }) {
  return (
    <table className="table table-striped table-sm">
      <tbody>
        <tr>
          <th scope="row">Description</th>
          <td>{
            term.description
              ||
            <i>
              None; add a <span style={mono}>docstring</span> to the class
            </i>
            }
          </td>
        </tr>

        <tr>
          <th scope="row">Code</th>
          <td>
            {
              term.module ?
                <span>
                  Class <span style={mono}>{term.class_name}</span> in module <span style={mono}>{term.module}</span>
                </span>
                : <span>
                  Method <span style={mono}>perform()</span> of Module <span style={mono}>{term.class_name}</span>. <b>Convert to class to get better documentation!</b>
                </span>
            }
          </td>
        </tr>

        <tr>
          <th scope="row">Workflows</th>
          <td>
            { term.workflows.length > 0 ?
                term.workflows.map((w) => <u key="w">{w}</u>)
                : <i>Not part of a workflow</i>
            }
          </td>
        </tr>

        <tr>
          <th scope="row">Source Table</th>
          <td>
            {
              term.source_table_name ||
                <i>
                  None; add <span style={mono}>BQ_SOURCE_TABLE_NAME</span> to the class
                </i>
            }
          </td>
        </tr>

        <tr>
          <th scope="row">Output Table</th>
          <td>
            {
              term.output_table_name ?
                <span style={mono}>{term.output_table_name}</span>
                : <i>
                  None; add <span style={mono}>BQ_OUTPUT_TABLE_NAME</span> to the class
                </i>
            }
          </td>
        </tr>

        <tr>
          <th scope="row">Names</th>
          <td>
            <ul>
              { term.names.map(n => <li key={n} style={mono}>{n}</li>) }
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

function TermBody({ term }) {
  if (term.term_type === "concept") {
    return <Concept term={ term } />
  } else if (term.term_type === "table") {
    return <Table term={ term } />
  } else if (term.term_type === "operator") {
    return <Operator term={ term } />
  } else {
    return <pre>{ JSON.stringify(term, null, 2) }</pre>;
  }
}

function TermDetails({ term, onHide }) {
  if (!term) return null;

  return (
    <Modal show centered size="lg" onHide={ onHide }>
      <Modal.Header closeButton>
        <Modal.Title>{ term.term_type } <i>{ term.term_name }</i></Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <TermBody term={ term } />
      </Modal.Body>
    </Modal>
  );
}

function TermsTable({ terms, onClick }) {
  const termRows = terms
    .sort((a, b) => a.term_name < b.term_name ? -1 : 1)
    .map((term) => (
    <tr key={ `${term.term_name}-${term.term_type}` }>
      <th scope="row">
        <button
          className="btn btn-link"
          onClick={ (evt) => onClick({ target: { value: term.term_name } }) }
        >
          {term.term_name}
        </button>
      </th>
      <td>{term.term_type}</td>
    </tr>
  ));

  return (
    <table className="table table-striped table-sm">
      <thead>
        <tr>
          <th scope="col">Term</th>
          <th scope="col">Term Type</th>
        </tr>
      </thead>

      <tbody>
        { termRows }
      </tbody>
    </table>
  );
}

function DocsPage({ terms }) {
  const filter = useStore(state => state.searchParams.filter);

  const term = useStore(state => state.searchParams.term);
  const termDetails = getTerm(terms, term);

  const filteredTerms = terms.filter(term => (
    term.term_name.indexOf(filter) >= 0 || term.term_type.indexOf(filter) >= 0
  ));

  const updateParam = (field, val) => {
    storeApi.setState({
      searchParams: produce(storeApi.getState().searchParams, (draft) => {
        draft[field] = val;
      }),
    });
  };

  return (
    <>
      <TermFilter
        filter={ filter }
        onChange={ (evt) => updateParam('filter', evt.target.value) }
      />

      <TermDetails
        term={termDetails}
        onHide={ (evt) => updateParam('term', null) }
      />

      <TermsTable
        terms={ filteredTerms }
        onClick={ (evt) => updateParam('term', evt.target.value) }
      />
    </>
  );
}

export default function Docs() {
  const docRef = firebase.firestore().doc(`docs/terms`);
  const [doc, loading, error] = useDocumentData(docRef);

  if (loading) {
    return (<span className="spinner-border" role="status"></span>);
  }

  if (error) {
    return (<span>Error: { `${error}` }</span>);
  }

  return (
    <DocsPage
      terms={doc.terms}
    />
  );
}
