
import React, { useEffect } from 'react';


import TaskGrid from './TaskGrid';
import TaskFilter from './TaskFilter';
import TaskDetails from './TaskDetails';

import useStore, { storeApi } from './store';
import shallow from 'zustand/shallow';

function Tasks() {
  const error = useStore(
    state => state.tasksLoadingError,
    // shallow equality to do multi-pick (new array each time)
    shallow,
  );

  // clean up our firebase subscriptions and grid settings on unmount
  useEffect(() => storeApi.getState().onGridUnmount, []);

  if (error) {
    return (
      <div>{error.name}: [code {error.code}] {error.message}</div>
    );
  }

  return (
    <>
      <TaskDetails />

      <TaskFilter />

      <TaskGrid />
    </>
  );
}

export default Tasks;
