
// main namespace import
import * as firebase from 'firebase/app';

// import auth and firestore
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/performance';

// configure the app
const config = {
  apiKey: "AIzaSyD9nHXiuHE1bxAF07VE-Uy0qSmw3J29D6c",
  authDomain: "datapipes.firebaseapp.com",
  databaseURL: "https://datapipes.firebaseio.com",
  projectId: "datapipes",
  storageBucket: "datapipes.appspot.com",
  messagingSenderId: "251052559683",
  appId: "1:251052559683:web:6972b35b64a39c652173f6",
  measurementId: "G-7HZG8TPWQ3"
};

const firebaseApp = firebase.initializeApp(config);

// app is default export
export default firebaseApp;

// as a convenience, export the pieces of functionality we're using
export const auth = firebase.auth;
export const GoogleAuthProvider = firebase.auth.GoogleAuthProvider;

// performance breaks tests
export const perf = process.env.NODE_ENV === 'test' ? null : firebase.performance();
