
import React from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import {
  addMinutes,
  compareAsc,
  differenceInMinutes,
  isEqual,
  startOfDay,
  parseISO,
} from 'date-fns';


import { format } from 'date-fns-tz';
import useStore from './store';

const localZone = format(Date.now(), 'z');
const localFmt = `yyyy-MM-dd HH:mm:ss '${localZone}'`;
const utcFmt = `yyyy-MM-dd HH:mm:ss 'UTC'`;

function tzDate(d) {
  return d.isUTC ? addMinutes(d, d.getTimezoneOffset()) : d
}

function displayFormatter({ value }) {
  if (!value) return null;
  const newValue = typeof(value) === "string" ? parseISO(value): value
  const tzFmt = newValue.isUTC ? utcFmt : localFmt;
  return format(tzDate(newValue), tzFmt);
}

function diffMinutes(str1, str2) {
  if (!str1 || !str2) {
    return null;
  }

  const d1 = new Date(str1);
  const d2 = new Date(str2);
  return Math.abs(differenceInMinutes(d1, d2));
}

function datesEqual(v1, v2) {
  if(!v1 && !v2) return true;
  return isEqual(v1, v2);
}

function compareDate(filterDate, cellValue) {
  if (!cellValue) return false;
  const cellDate = startOfDay(tzDate(cellValue));
  return compareAsc(cellDate, filterDate);
}

function formatMinutes(minutes) {
  if (minutes === null) {
    return 'N/A';
  } else if (minutes < 1) {
    return '< minute';
  } else if (minutes <= 60) {
    return `${minutes} minutes`;
  } else {
    const hours = Math.floor(minutes / 60);
    const leftover = minutes % 60;
    return `${hours} hours ${leftover} minutes`;
  }
}

const dateColOpts = {
  valueFormatter: displayFormatter,
  equals: datesEqual,
  filter: "agDateColumnFilter",
};

const dateFilterOpts = {
  browserDatePicker: true,
  comparator: compareDate,
  suppressAndOrCondition: true,
  newRowsAction: 'keep',
};

const timingColOpts = {
  valueFormatter: ({value}) => formatMinutes(value),
  filter: "agNumberColumnFilter",
};

const columns = [
  {
    headerName: 'Basic Job Info',
    children: [
      {
        field: 'operator',
        headerName: 'Operator',
      },
      {
        field: 'status',
        headerName: 'Status',
      },
      {
        field: 'project',
        headerName: 'Project',
      },
    ],
  },
  {
    headerName: 'Job Parameters',
    children: [
      {
        field: 'start_time',
        headerName: 'Start Time',
        isUTC: true,
        ...dateColOpts,
        filterParams: { ...dateFilterOpts },
      },
      {
        field: 'end_time',
        headerName: 'End Time',
        isUTC: true,
        ...dateColOpts,
        filterParams: { ...dateFilterOpts },
      },
      {
        colId: 'window_size',
        headerName: 'Window',
        valueGetter: ({data}) => diffMinutes(data.start_time, data.end_time),
        ...timingColOpts,
      },

      {
        field: 'version',
        headerName: 'Version',
      },
      {
        field: 'context',
        headerName: 'Pod Context',
        equals: () => true,  // TODO: change detection for dependencies (updated-at sub-field?)
        valueFormatter: ({ value }) => JSON.stringify(value),
      },
      {
        field: 'attempts',
        headerName: 'Attempts',
        equals: () => true,  // TODO: change detection for dependencies (updated-at sub-field?)
        valueFormatter: ({ value }) => (value || []).length,
      },
    ],
  },
  {
    headerName: 'Timings',
    children: [
      {
        field: 'created_at',
        headerName: 'Created At',
        ...dateColOpts,
        filterParams: { ...dateFilterOpts },
      },
      {
        field: 'scheduled_at',
        headerName: 'Scheduled At',
        ...dateColOpts,
        filterParams: { ...dateFilterOpts },
      },
      {
        field: 'waiting_at',
        headerName: 'Waiting At',
        ...dateColOpts,
        filterParams: { ...dateFilterOpts },
      },
      {
        field: 'running_at',
        headerName: 'Running At',
        ...dateColOpts,
        filterParams: { ...dateFilterOpts },
      },
      {
        field: 'completed_at',
        headerName: 'Completed At',
        ...dateColOpts,
        filterParams: { ...dateFilterOpts },
      },
      {
        field: 'cleanup_at',
        headerName: 'Cleanup At',
        ...dateColOpts,
        filterParams: { ...dateFilterOpts },
      },
      {
        colId: 'waited_for',
        headerName: 'Waited For',
        valueGetter: ({data}) => diffMinutes(data.waiting_at, data.running_at),
        ...timingColOpts,
      },
      {
        colId: 'ran_for',
        headerName: 'Ran For',
        valueGetter: ({data}) => diffMinutes(data.running_at, data.completed_at),
        ...timingColOpts,
      },
    ]
  },
  {
    headerName: 'Tracking and Dependency',
    children: [
      {
        field: 'task_uuid',
        headerName: 'ID',
        filter: "agTextColumnFilter",
      },
      {
        field: 'scheduled_by',
        headerName: 'Scheduled By',
        filter: "agTextColumnFilter",
      },
      {
        field: 'dispatched_by',
        headerName: 'Dispatched By',
        filter: "agTextColumnFilter",
      },
      {
        field: 'cleanup_by',
        headerName: 'Cleanup By',
        filter: "agTextColumnFilter",
      },
      {
        field: 'dependencies',
        headerName: 'Dependencies',
        equals: () => true,  // TODO: change detection for dependencies (updated-at sub-field?)
      },
      {
        field: 'pod_name',
        headerName: 'Pod',
        filter: "agTextColumnFilter",
      },
    ],
  }
];

function TaskGrid() {
  const onGridReady = useStore(state => state.onGridReady);

  return (
    <div
      className="ag-theme-balham w-100"
      style={{
        height: '90vh',
      }}
    >
      <AgGridReact
        onGridReady={ onGridReady }
        columnDefs={ columns }
        getRowNodeId={(row) => row.task_uuid}
        enableCellChangeFlash={true}
        floatingFilter={true}
        rowSelection="single"
        rowDeselection={true}
        defaultColDef={{
          resizable: true,
          sortable: true,
          filter: true,
          suppressMenu: true,
          filterParams: {
            newRowsAction: 'keep',
          }
        }}
      />
    </div>
  );
}

export default TaskGrid;
