
import React, { useState, useCallback, useEffect } from 'react';

import { DateTime } from "luxon";

import Modal from 'react-bootstrap/Modal';

import { useDocumentData } from 'react-firebase-hooks/firestore';
import shallow from 'zustand/shallow';
import produce from 'immer';

import { CreatedToast, createTask, projects } from './Create';
import useStore, { storeApi } from './store';
import firebase from './firebaseApp';

function SubmitManager({
  project, workflowName, start, end, downstreamOf, upTo, submitting, onSubmitComplete
}) {
  const [numTasksCreated, setNumTasksCreated] = useState(0);
  const [taskCreateError, setTaskCreateError] = useState(null);

  const onSuccess = useCallback((numTasks) => {
    onSubmitComplete();
    setNumTasksCreated(numTasks);
  }, [onSubmitComplete, setNumTasksCreated]);

  const onError = useCallback((error) => {
    console.log("Error adding task: " + error)
    onSubmitComplete();
    setTaskCreateError(error);
  }, [onSubmitComplete, setTaskCreateError]);

  const handleSubmit = useCallback(() => {
    const formValues = {
      project,
      operator: 'workflows.scheduler',
      start: DateTime.fromISO(start, {zone: 'utc'}).toISO(),
      end: DateTime.fromISO(end, {zone: 'utc'}).toISO(),
      extraOptions: `workflow_name=${workflowName},downstream_of=${downstreamOf},up_to=${upTo}`,
    };

    createTask(formValues, onSuccess, onError);
  }, [project, workflowName, start, end, downstreamOf, upTo, onSuccess, onError]);

  const handleToastClose = useCallback(() => {
    setNumTasksCreated(0);
    setTaskCreateError(null);
  }, [setNumTasksCreated, setTaskCreateError]);

  useEffect(
    () => { if (submitting) { handleSubmit(); } },
    [submitting, handleSubmit]
  );

  return (
    <div>
      <Modal show={submitting}>
        <Modal.Header>
          <Modal.Title>Scheduling workflow run(s)...</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span className="spinner-border" role="status"></span>
        </Modal.Body>
      </Modal>

      <div className="row justify-content-center">
        <CreatedToast
          numTasks={numTasksCreated}
          error={taskCreateError}
          show={taskCreateError || numTasksCreated > 0}
          handleClose={handleToastClose}/>
      </div>
    </div>
  );
}

function WorkflowForm({ tasks, workflowName, downstreamOf, upTo, loading }) {
  const [project, start, end] = useStore(
    state => [
      state.searchParams.project,
      state.searchParams.start,
      state.searchParams.end,
    ],
    shallow
  );

  const updaterMaker = (param) => {
    return (evt) => {
      storeApi.setState({
        searchParams: produce(storeApi.getState().searchParams, (draft) => {
          draft[param] = evt.target.value;
        }),
      });
    }
  };

  const yesterday = DateTime.utc().startOf('day').minus({days: 1});

  const [submitting, setSubmitting] = useState(false);
  const onSubmitComplete = useCallback(
    () => setSubmitting(false),
    [setSubmitting],
  )

  return (
    <div className="col">
      <SubmitManager
        project={ project }
        workflowName={ workflowName }
        start={ start }
        end={ end }
        downstreamOf={ downstreamOf }
        upTo={ upTo }
        submitting={ submitting }
        onSubmitComplete={ onSubmitComplete }
      />

      <div className="form-row">
        <div className="form-group col">
          <label htmlFor="project">Project</label>
          <select className="form-control" name="project" id="project"
            value={ project }
            onChange={ updaterMaker('project') }
          >
            {projects.map(p => <option value={p} key={p}>{p}</option>)}
          </select>
        </div>

        <div className="form-group col">
          <label htmlFor="workflow">Workflow</label>
          <select className="form-control" name="workflow" id="workflow"
            value={ workflowName }
            onChange={ updaterMaker('workflowName') }
          >
            {['daily', 'hourly'].map(p => <option value={p} key={p}>{p}</option>)}
          </select>
        </div>
      </div>

      <div className="form-row">
        <div className="form-group col">
          <label htmlFor="start">First Day</label>
          <input className="form-control" type="date" name="start" id="start"
            max={end}
            value={start}
            onChange={ updaterMaker('start') }
          />
        </div>

        <div className="form-group col">
          <label htmlFor="end">Last Day</label>
          <input className="form-control" type="date" name="end" id="end"
            min={start}
            max={yesterday.toISODate()}
            value={end}
            onChange={ updaterMaker('end') }
          />
        </div>
      </div>

      <div className="form-row">
        <div className="form-group col">
          <label htmlFor="downstreamOf">Downstream Of:</label>
          <select className="form-control" name="downstreamOf" id="downstreamOf"
            value={ downstreamOf }
            onChange={ updaterMaker('downstreamOf') }
          >
            {tasks.map(t => <option value={t} key={t}>{t}</option>)}
          </select>
        </div>
      </div>

      <div className="form-row">
        <div className="form-group col">
          <label htmlFor="upTo">Up To:</label>
          <select className="form-control" name="upTo" id="upTo"
            value={ upTo }
            onChange={ updaterMaker('upTo') }
          >
            {tasks.map(t => <option value={t} key={t}>{t}</option>)}
          </select>
        </div>
      </div>

      <div className="form-row">
        <div className="form-group col">
          <button className="btn btn-danger"
            disabled={ submitting }
            onClick={ (evt) => setSubmitting(true) }
          >
            Schedule
          </button>
        </div>
      </div>
    </div>
  );
}

function WorkflowImg({ error, loading, data }) {
  if (error) {
    return (<span>Error: { `${error}` }</span>);
  }

  if (loading) {
    return (<span className="spinner-border" role="status"></span>);
  }

  if (!data) {
    return (<span>no doc</span>);
  }

  return (
    <img
      style={ {maxWidth: '100%'} }
      alt="workflow visualization"
      src={ `data:image/png;base64,${data}` }
    />
  );
}

function WorkflowPage({ docRef, workflowName, downstreamOf, upTo }) {
  const [doc, loading, error] = useDocumentData(docRef);

  const data = doc ? doc.png.toBase64() : null;
  const tasks = doc ? doc.tasks : [];

  // in order with a blank at the beginning
  tasks.sort();
  tasks.unshift('');

  // make sure currently-selected tasks are included
  if (downstreamOf && !tasks.includes(downstreamOf)) {
    tasks.push(downstreamOf);
  }
  if (upTo && !tasks.includes(upTo)) {
    tasks.push(upTo);
  }

  return (
    <div className="row">
      <WorkflowForm
        tasks={ tasks }
        workflowName={ workflowName }
        downstreamOf={ downstreamOf }
        upTo={ upTo }
        loading={ loading }
      />

      <div className="col">
        <WorkflowImg error={ error } loading={ loading } data={ data } />
      </div>
    </div>
  );
}

export default function Workflow() {
  const [workflowName, downstreamOf, upTo] = useStore(
    state => [
      state.searchParams.workflowName,
      state.searchParams.downstreamOf,
      state.searchParams.upTo,
    ],
    shallow
  );

  let docName = workflowName;
  if( downstreamOf ) {
    docName = `${docName}-from-${downstreamOf}`;
  }
  if (upTo ) {
    docName = `${docName}-to-${upTo}`;
  }

  const docRef = firebase.firestore().doc(`docs/${docName}`);

  return (
    <WorkflowPage
      docRef={docRef}
      workflowName={workflowName}
      downstreamOf={downstreamOf}
      upTo={upTo}
    />
  );
}
